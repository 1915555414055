<template>
  <div class="wrap-query">
    <el-form ref="form" :model="form" :label-position="labelPosition" label-width="80px">
      <el-form-item label="AWB#">
        <el-input v-model="form.awbNo" size="small"></el-input>
      </el-form-item>
      <el-form-item label="MAWB#">
        <el-input v-model="form.mawbNo" size="small"></el-input>
      </el-form-item>
      <el-form-item label="Origin">
        <el-input v-model="form.origin" size="small"></el-input>
      </el-form-item>
      <el-form-item label="Dest.">
        <el-input v-model="form.dest" size="small"></el-input>
      </el-form-item>
      <el-form-item label="Flight Date">
        <el-date-picker
          v-model="form.flightDate"
          type="daterange"
          start-placeholder="From Date"
          end-placeholder="To Date"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Execution Date">
        <el-date-picker
          v-model="form.executionDate"
          type="daterange"
          start-placeholder="From Date"
          end-placeholder="To Date"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Create Date">
        <el-date-picker
          v-model="form.createDate"
          type="daterange"
          start-placeholder="From Date"
          end-placeholder="To Date"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <footer class="submit-button">
      <el-button size="small" class="public-fonts" @click="queryReset()">Reset</el-button>
      <el-button type="primary" size="small" class="public-fonts" @click="querySubmit()">Query</el-button>
    </footer>
  </div>
</template>

<script>
import { getFasaData } from '../../../api/afsa-data/afsa-api'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'query',
  data () {
    return {
      form: {
        awbNo: null,
        mawbNo: null,
        origin: null,
        dest: null,
        flightDate: null,
        executionDate: null,
        createDate: null
      },
      labelPosition: 'top'
    }
  },
  methods: {
    queryReset () {
      this.form.awbNo = null
      this.form.mawbNo = null
      this.form.origin = null
      this.form.dest = null
      this.form.flightDate = null
      this.form.executionDate = null
      this.form.createDate = null
    },
    querySubmit () {
      const obj = {
        data: {
          officeCode: getOfficeCode(),
          awbNo: this.form.awbNo ? this.form.awbNo.toUpperCase() : null,
          mawbNo: this.form.mawbNo ? this.form.mawbNo.toUpperCase() : null,
          origin: this.form.origin ? this.form.origin.toUpperCase() : null,
          dest: this.form.dest ? this.form.dest.toUpperCase() : null,
          flightDateFrom: this.form.flightDate ? this.form.flightDate[0] : null,
          flightDateTo: this.form.flightDate ? this.form.flightDate[1] : null,
          executionDateFrom: this.form.executionDate ? this.form.executionDate[0] : null,
          executionDateTo: this.form.executionDate ? this.form.executionDate[1] : null,
          createDateFrom: this.form.createDate ? this.form.createDate[0] : null,
          createDateTo: this.form.createDate ? this.form.createDate[1] : null
        },
        loading: true
      }
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Query Successfully!',
            type: 'success',
            showClose: true,
            duration: 8000,
            offset: 120
          })
          this.$store.dispatch('changequeryResultAFsa', res.data.DATA)
          if (this.$route.path !== '/afsa-data/qurey-result') {
            this.$router.push({ path: '/afsa-data/qurey-result', query: obj.data })
          } else {
            this.$router.replace({ query: obj.data })
          }
          this.$emit('query-drawer', { close: false })
          this.form.awbNo = null
          this.form.mawbNo = null
          this.form.origin = null
          this.form.dest = null
          this.form.flightDate = null
          this.form.executionDate = null
          this.form.createDate = null
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-query {
  // padding: 0 15px;
  ::v-deep{
    .el-date-editor.el-input, .el-date-editor.el-input__inner{
      width: 100% !important;
    }
    .el-form-item{
      margin-bottom: 5px;
    }
    .el-form-item__label {
      line-height: 33px;
    }
    // .el-date-editor .el-range-separator{
    //   padding: 0;
    // }
  }
}
</style>
