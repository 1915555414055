<template>
  <div class="wrap-query">
    <el-form ref="form" :model="form" :label-position="labelPosition" label-width="80px">
      <el-form-item label="MAWB#">
        <el-input v-model="form.mawb"></el-input>
      </el-form-item>
    </el-form>
    <footer class="submit-button">
      <el-button @click="queryReset()" size="small" class="public-fonts">Reset</el-button>
      <el-button type="primary" @click="querySubmit()" size="small" class="public-fonts">Load Data</el-button>
    </footer>
  </div>
</template>

<script>
import { getFasaDataBymawbNo, getFasaData } from '../../../api/afsa-data/afsa-api'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'by-awb-query',
  data () {
    return {
      form: {
        mawb: null
      },
      labelPosition: 'top'
    }
  },
  methods: {
    queryReset () {
      this.form.mawb = null
    },
    querySubmit () {
      const obj = { officeCode: getOfficeCode(), mawbNo: this.form.mawb ? this.form.mawb.toUpperCase() : null }
      getFasaDataBymawbNo(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `Load awb data ${this.form.mawb} successfully!`,
            type: 'success',
            showClose: true,
            duration: 8000,
            offset: 120
          })
          const params = {
            data: obj,
            loading: true
          }
          this.queryBymawb(params)
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(() => {
      })
    },
    queryBymawb (obj) {
      getFasaData(obj).then(res => {
        if (res.data.CODE === 200) {
          this.$store.dispatch('changeLoadDataResultAFsa', res.data.DATA)
          if (this.$route.path !== '/afsa-data/load-data-result') {
            this.$router.push({ path: '/afsa-data/load-data-result', query: obj.data })
          } else {
            this.$router.replace({ query: obj.data })
          }
          this.$emit('awbResultdrawer', { close: false })
          this.form.mawb = null
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
