import { render, staticRenderFns } from "./by-awb-query.vue?vue&type=template&id=da4002b8&scoped=true&"
import script from "./by-awb-query.vue?vue&type=script&lang=js&"
export * from "./by-awb-query.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da4002b8",
  null
  
)

export default component.exports